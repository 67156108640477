import { call, fork, take, takeEvery, takeLeading, put, select, delay } from 'redux-saga/effects'
import _ from 'lodash'
import heartbeat from 'lib/saga/heartbeat'
import takeLeadingWithPayload from 'lib/saga/takeLeadingWithPayload'
import api from 'lib/api/client'
import { POSTS_PER_PAGE, INTERVAL } from '../const'
import Actions, {
  fetchCurrentUser as fetchCurrentUserAction,
  receiveCurrentUser,
  fetchShiftBook as fetchShiftBookAction,
  requestShiftBookStart,
  requestShiftBookSuccess,
  receiveShiftBook,
  markAsReadSuccess,
  removeReadNotes as removeReadNotesAction
} from '../actions'
import {
  selectToken,
  selectCurrentUserUnreadShiftBookNoteIds,
  selectShiftBookNotesAllIds,
  selectShiftBookNotesPage,
  selectNotFetchedNoteIds,
  selectReadNotesIds,
  selectDropdownIsOpen
} from '../selectors'

function * onDropdownStateChanged ({ payload: isOpen }) {
  yield call(removeReadNotes)
  if (isOpen) {
    yield put(fetchCurrentUserAction())
    yield take(Actions.RECEIVE_CURRENT_USER)
    yield call(fetchShiftBookOnOpen)
  }
}

function * fetchShiftBookOnOpen () {
  const page = yield select(selectShiftBookNotesPage)
  if (page === 1) {
    yield put(fetchShiftBookAction())
  }
}

function * fetchShiftBook () {
  if (!(yield call(hasNewNotesToFetch))) {
    return
  }

  const page = yield select(selectShiftBookNotesPage)
  yield put(requestShiftBookStart())

  const token = yield select(selectToken)
  const notes = yield call(
    api.get,
    `/users/unread_shift_book_notes?page=${page}&per=${POSTS_PER_PAGE}`,
    { token }
  )

  yield put(receiveShiftBook(notes))

  const fetchedAll = notes.length === POSTS_PER_PAGE
  yield put(requestShiftBookSuccess(fetchedAll))
}

function * hasNewNotesToFetch () {
  const notFetchedNotes = yield select(selectNotFetchedNoteIds)
  return !!notFetchedNotes.length
}

function * removeReadNotes () {
  const unreadIds = yield select(selectCurrentUserUnreadShiftBookNoteIds)
  const readIds = yield select(selectReadNotesIds)
  yield put(removeReadNotesAction(_.difference(unreadIds, readIds)))
}

function * markAsRead ({ payload: id }) {
  try {
    const token = yield select(selectToken)
    yield call(
      api.post,
      'shift_book_notes/set_read',
      { shiftBookNoteIds: [id] },
      { token }
    )
    yield put(markAsReadSuccess(id))
  } catch (err) {}
}

function * fetchCurrentUser () {
  const token = yield select(selectToken)
  const user = yield call(api.get, '/users/me', { token })
  yield put(receiveCurrentUser(user))
}

function * fetchIfDropdownOpen () {
  const dropdownIsOpen = yield select(selectDropdownIsOpen)
  if (dropdownIsOpen) {
    const notes = (yield select(selectShiftBookNotesAllIds)).length
    if (notes < POSTS_PER_PAGE) {
      yield put(fetchShiftBookAction())
    }
  }
}

export default function * rootSaga () {
  if (process.env.NODE_ENV === 'development') {
    yield fork(heartbeat, 'ShiftBookNotifications')
  }

  yield takeEvery(Actions.ON_DROPDOWN_STATE_CHANGED, onDropdownStateChanged)
  yield takeLeading(Actions.FETCH_SHIFT_BOOK, fetchShiftBook)
  yield fork(takeLeadingWithPayload, Actions.MARK_AS_READ, markAsRead)
  yield takeEvery(Actions.FETCH_CURRENT_USER, fetchCurrentUser)
  yield takeEvery(Actions.RECEIVE_CURRENT_USER, fetchIfDropdownOpen)

  while (true) {
    yield put(fetchCurrentUserAction())
    yield take(Actions.RECEIVE_CURRENT_USER)
    yield delay(INTERVAL)
  }
}
