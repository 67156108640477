import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import Button from 'components/Button'
import Loader from 'components/Loader'
import { fetchShiftBook } from './actions'
import {
  selectNotesLoading,
  selectNotes,
  selectNotFetchedNoteIds
} from './selectors'
import ShiftBookNotificationsNote from './ShiftBookNotificationsNote'

const noPaddingStyle = {
  marginBottom: 0,
  paddingBottom: 0
}

const ShiftBookNotificationsList = ({ loading, notes, hasMoreToFetch, fetchShiftBook }) => {
  const showLoadMore = !!notes.length && hasMoreToFetch
  return (
    <div className='shift-book-notifications__container'>
      <div className='shift-book-notifications__header'>
        <FormattedMessage id='workspace.menu.shiftBookNotifications.title' />
      </div>
      <div className='shift-book-notifications__list'>
        {!!(loading || notes.length) && (
          <React.Fragment>
            {!notes.length && loading && <Loader transparent />}
            {notes.map((note, index) => {
              const style = showLoadMore || notes.length - 1 !== index ? undefined : noPaddingStyle
              return <ShiftBookNotificationsNote key={note.id} note={note} style={style} />
            })}
            {showLoadMore && (
              <center>
                <Button color='blue' isLoading={loading} onClick={fetchShiftBook}>
                  <FormattedMessage id='workspace.menu.shiftBookNotifications.loadMore' />
                </Button>
              </center>
            )}
          </React.Fragment>
        )}
      </div>
      <div className='shift-book-notifications__footer' />
    </div>
  )
}

ShiftBookNotificationsList.propTypes = {
  loading: PropTypes.bool,
  notes: PropTypes.array,
  hasMoreToFetch: PropTypes.bool,
  fetchShiftBook: PropTypes.func
}

const mapStateToProps = state => {
  return {
    loading: selectNotesLoading(state),
    notes: selectNotes(state),
    hasMoreToFetch: !!selectNotFetchedNoteIds(state).length
  }
}

const mapDispatchToProps = dispatch => ({
  fetchShiftBook: () => dispatch(fetchShiftBook())
})

export default connect(mapStateToProps, mapDispatchToProps)(ShiftBookNotificationsList)
