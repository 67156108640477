import { createAction } from 'redux-actions'

const Actions = {
  SET_TOKEN: 'SET_TOKEN',
  FETCH_CURRENT_USER: 'FETCH_CURRENT_USER',
  RECEIVE_CURRENT_USER: 'RECEIVE_CURRENT_USER',
  REQUEST_SHIFT_BOOK_START: 'REQUEST_SHIFT_BOOK_NEW_START',
  REQUEST_SHIFT_BOOK_SUCCESS: 'REQUEST_SHIFT_BOOK_NEW_SUCCESS',
  RECEIVE_SHIFT_BOOK: 'RECEIVE_SHIFT_BOOK',
  ON_DROPDOWN_STATE_CHANGED: 'ON_DROPDOWN_STATE_CHANGED',
  FETCH_SHIFT_BOOK: 'FETCH_SHIFT_BOOK',
  MARK_AS_READ: 'MARK_AS_READ',
  MARK_AS_READ_SUCCESS: 'MARK_AS_READ_SUCCESS',
  REMOVE_READ_NOTES: 'REMOVE_READ_NOTES'
}

export default Actions

export const setToken = createAction(Actions.SET_TOKEN)
export const fetchCurrentUser = createAction(Actions.FETCH_CURRENT_USER)
export const receiveCurrentUser = createAction(Actions.RECEIVE_CURRENT_USER)
export const requestShiftBookStart = createAction(Actions.REQUEST_SHIFT_BOOK_START)
export const requestShiftBookSuccess = createAction(Actions.REQUEST_SHIFT_BOOK_SUCCESS)
export const receiveShiftBook = createAction(Actions.RECEIVE_SHIFT_BOOK)
export const onDropdownStateChanged = createAction(Actions.ON_DROPDOWN_STATE_CHANGED)
export const fetchShiftBook = createAction(Actions.FETCH_SHIFT_BOOK)
export const markAsRead = createAction(Actions.MARK_AS_READ)
export const markAsReadSuccess = createAction(Actions.MARK_AS_READ_SUCCESS)
export const removeReadNotes = createAction(Actions.REMOVE_READ_NOTES)
