import { combineReducers } from 'redux'
import { handleActions } from 'redux-actions'
import _ from 'lodash'
import { allById, allIds } from 'lib/reducers'
import { POSTS_PER_PAGE } from './const'
import Actions from './actions'

const config = handleActions(
  {
    [Actions.SET_TOKEN]: (state, { payload: token }) => ({ ...state, token })
  },
  { token: null, mode: null, locale: null, timezone: null }
)

const currentUser = handleActions(
  {
    [Actions.RECEIVE_CURRENT_USER]: (state, { payload }) => payload
  },
  {}
)

const unreadCount = handleActions(
  {
    [Actions.RECEIVE_CURRENT_USER]: (state, { payload: user }) => user.unreadShiftBookNoteIds.length,
    [Actions.MARK_AS_READ_SUCCESS]: state => state - 1
  },
  0
)

const shiftBookNotes = handleActions(
  {
    [Actions.REQUEST_SHIFT_BOOK_START]: state => ({
      ...state,
      loading: true
    }),
    [Actions.REQUEST_SHIFT_BOOK_SUCCESS]: (state, { payload: fetchedAll }) => ({
      ...state,
      loading: false,
      page: state.page + (fetchedAll ? 1 : 0),
      removed: 0
    }),
    [Actions.RECEIVE_SHIFT_BOOK]: (state, { payload }) => ({
      ...state,
      allById: {
        ...state.allById,
        ...allById(payload)
      },
      allIds: _.uniq([...state.allIds, ...allIds(payload)])
    }),
    [Actions.RECEIVE_CURRENT_USER]: (state, { payload: { unreadShiftBookNoteIds } }) => {
      const length = unreadShiftBookNoteIds.length
      const maxPage = !length ? 1 : Math.floor(length / POSTS_PER_PAGE + 1)
      return state.page <= maxPage ? state : { ...state, page: maxPage }
    },
    [Actions.MARK_AS_READ_SUCCESS]: state => ({
      ...state,
      removed: state.removed + 1,
      page: Math.max((state.removed % POSTS_PER_PAGE === 0 ? state.page - 1 : state.page), 1)
    }),
    [Actions.REMOVE_READ_NOTES]: (state, { payload: unreadIds }) => ({
      ...state,
      allById: _.fromPairs(_.toPairs(state.allById).filter(([id]) => unreadIds.includes(+id))),
      allIds: _.intersection(state.allIds, unreadIds)
    })
  },
  { page: 1, removed: 0, allById: {}, allIds: [], loading: false }
)

const readNotesIds = handleActions(
  {
    [Actions.MARK_AS_READ_SUCCESS]: (state, { payload: id }) => _.uniq([...state, id]),
    [Actions.REMOVE_READ_NOTES]: () => []
  },
  []
)

const dropdownIsOpen = handleActions(
  {
    [Actions.ON_DROPDOWN_STATE_CHANGED]: (state, { payload }) => payload
  },
  false
)

export default combineReducers({
  config,
  currentUser,
  unreadCount,
  shiftBookNotes,
  readNotesIds,
  dropdownIsOpen
})
