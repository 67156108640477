import { createSelector } from 'reselect'
import _ from 'lodash'
import moment from 'moment'

export const selectToken = state => state.config.token

export const selectCurrentUser = state => state.currentUser
export const selectCurrentUserUnreadShiftBookNoteIds = state =>
  (selectCurrentUser(state) || {}).unreadShiftBookNoteIds || []

export const selectNotesCount = state => state.unreadCount

export const selectShiftBookNotesAllIds = state => state.shiftBookNotes.allIds
export const selectShiftBookNotesById = state => state.shiftBookNotes.allById
export const selectShiftBookNotesPage = state => state.shiftBookNotes.page

export const selectNotesLoading = state => state.shiftBookNotes.loading

export const selectNotFetchedNoteIds = createSelector(
  selectCurrentUserUnreadShiftBookNoteIds,
  selectShiftBookNotesById,
  (ids, notesById) => ids.filter(id => !(id in notesById))
)
export const selectNotes = createSelector(
  state => state.shiftBookNotes,
  ({ allById }) => _.values(allById).sort((a, b) => {
    return moment(a.createdAt).isAfter(b.createdAt) ? 1 : -1
  })
)

export const selectReadNotesIds = state => state.readNotesIds
export const selectIsMarkedAsRead = (state, id) =>
  state.readNotesIds.includes(id) || !selectCurrentUserUnreadShiftBookNoteIds(state).includes(id)

export const selectDropdownIsOpen = state => state.dropdownIsOpen
