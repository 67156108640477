import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import Icon from 'components/Icon'
import { DATETIME_FORMAT } from 'lib/const'
import moment from 'moment'
import { markAsRead } from './actions'
import { selectIsMarkedAsRead } from './selectors'

const ShiftBookNotificationsNote = ({ note, style, isMarkedAsRead, markAsRead }) => {
  return (
    <div className='shift-book-note shift-book-note--border-bottom' style={style}>
      <div className='shift-book-note__meta'>
        <div className='shift-book-note__info'>
          {note.locationSymbol && <strong>{note.locationSymbol}: </strong>}
          <span>{moment(note.createdAt).format(DATETIME_FORMAT)}</span>
          <br />
          <strong>{note.userName}</strong>
        </div>
        <div className='shift-book-note__actions'>
          {!isMarkedAsRead && (
            <div className='shift-book-note__read' onClick={markAsRead}>
              <Icon name='eye' className='shift-book-note__actions-icon' />
              <FormattedMessage id='workspace.menu.shiftBookNotifications.markAsRead'>
                {msg => <span className='shift-book-note__actions-text'>{msg}</span>}
              </FormattedMessage>
            </div>
          )}
        </div>
      </div>
      <div className='shift-book-note__body'>
        {note.body}
      </div>
    </div>
  )
}

ShiftBookNotificationsNote.propTypes = {
  note: PropTypes.object,
  style: PropTypes.object,
  isMarkedAsRead: PropTypes.bool,
  markAsRead: PropTypes.func
}

const mapStateToProps = (state, ownProps) => ({
  isMarkedAsRead: selectIsMarkedAsRead(state, ownProps.note.id)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  markAsRead: () => dispatch(markAsRead(ownProps.note.id))
})

export default connect(mapStateToProps, mapDispatchToProps)(ShiftBookNotificationsNote)
