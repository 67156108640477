import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import Icon from 'components/Icon'
import DropdownButton from 'components/DropdownButton'
import Badge from 'components/Badge'

import { onDropdownStateChanged } from './actions'
import { selectNotesCount } from './selectors'

import ShiftBookNotificationsList from './ShiftBookNotificationsList'

const dropdownStyle = {
  paddingTop: 6,
  paddingBottom: 6,
  fontSize: 12
}
const iconStyle = { marginRight: 9 }
const badgeStyle = { marginLeft: 9 }

const ShiftBookNotifications = ({ notesCount, onStateChanged }) => {
  return (
    <div className='shift-book-notifications'>
      <DropdownButton
        color='blue'
        dropdownColor='blue'
        style={dropdownStyle}
        onStateChanged={onStateChanged}
        content={({ close }) => <ShiftBookNotificationsList />}
      >
        <div className='flex-center'>
          <Icon name='file-text-o' style={iconStyle} />
          <FormattedMessage id='workspace.menu.shiftBookNotifications.title' />
          {!!notesCount && <Badge text={notesCount} size={19} style={badgeStyle} />}
        </div>
      </DropdownButton>
    </div>
  )
}

ShiftBookNotifications.propTypes = {
  notesCount: PropTypes.number,
  onStateChanged: PropTypes.func
}

const mapStateToProps = state => ({
  notesCount: selectNotesCount(state)
})

const mapDispatchToProps = dispatch => ({
  onStateChanged: isOpen => dispatch(onDropdownStateChanged(isOpen))
})

export default connect(mapStateToProps, mapDispatchToProps)(ShiftBookNotifications)
